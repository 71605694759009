@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap);

.authentication {
  width: 100%;
  font-family: Roboto, serif !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  line-height: 1.14 !important;
  letter-spacing: normal !important;

  background-color: #f8f8f8;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.authentication-container{
  width: 410px;
  top: 50%;
  left: 50%;

}

.authentication-container-title{
  margin: 0 0 28px 0;
  height: 30px;
  text-align: center;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.8);;
}

.authentication-container-privacy {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.authentication-dialog{
  width: 410px;
  /* min-height: 380px;*/
  height: fit-content;
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 30px;
}



.authentication-dialog button{
  width: 100%;
  font-size: 16px !important;
  border-radius: 4px;
  border: solid 1px #0067f3;
  background-color: #0067f3;
}

.authentication-dialog label{
  color: #373a3c;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.authentication-dialog-row{
  margin: 30px 0 0 0 ;
}

.authentication-dialog-action-row{
  margin: 16px 0 0 0 ;
}

.authentication-dialog a{
  color: #0275d8;
  text-align: center;
  cursor: pointer;
}

.privacy-link {
  cursor: default;
  color: #0a3ab4;
  font-size: 12px;
  font-weight: 600;
  display: block;
  font-family: 'Open Sans';
}


.stage-title {
  font-size: 28px;
  text-align: center;
  color: #000000;
}

.link{
  color: #0067f3;
  cursor: pointer;
}

.form-control-feedback.glyphicon {
  position: absolute;
}

.has-feedback label~.form-control-feedback {
  top: 20px;
}


.form-control-feedback {
  line-height: 34px;
  pointer-events: unset !important;
  cursor: pointer;
}

.has-feedback {
  position: relative;
}

.has-feedback img{
  position: absolute;
  right: 10px;
  top: 32px;
  width: 16px;
  height: 16px;
}

.error img{
  width: 16px;
  height: 16px;
}

.form-control{
  height: 34px;
}
.has-feedback label~.form-control-feedback{
  top: 30px !important;
}

.glyphicon-eye-open , .glyphicon-eye-close {
  color: rgba(87, 87, 87, 0.4);
}

.error-box{
  box-shadow: inset 0 0 0 1px #d0021b;
  background-color: rgba(208, 2, 27, 0.05);
  font-size: 14px;
  color: #d0021b;
  margin: 24px 0 10px 0;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.password-format-box{
  margin: 8px 0;
  font-size: 12px;
  line-height: 20px;
}

.password-format-box-title{
  /* font-weight: bold; */
  font-size: 14px;
}

.password-format-box .error{
  color:#d0021b
}

.has-error .checkbox, .has-error .checkbox-inline, .has-error .control-label, .has-error .help-block, .has-error .radio, .has-error .radio-inline, .has-error.checkbox label, .has-error.checkbox-inline label, .has-error.radio label, .has-error.radio-inline label {
  color: #a94442;
}
.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

.has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.permission-denied-wrapper {
  padding: 32px;
  text-align: center;
  margin: 0 auto;
  position: relative;
}

.permission-denied-wrapper-img {
  position: initial;
  max-width: 100%;
  margin-bottom: 20px;
}

.permission-denied-heading {
  max-width: 363px;
  width: 100%;
  height: 36px;
  margin-top: 50px;
  margin: 20px auto;
  padding-bottom: 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #58a2f0;
}

.permission-denied-message {
  max-width: 443px;
  width: 100%;
  height: 40px;
  margin: 0 auto;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.PypestreamLogo {
  width: 240px;
  height: 38px;
}

.pypestream-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pypestream-loader > div {
  min-width: 128px;
  min-height: 128px;
  height: 128px;
  width: 128px;
  position: relative;
}


.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

